import React from "react";
import { Link } from "react-router-dom";

function Pricing() {
  return (
    <>
      <div className="text-center text-white font-bold mt-[10rem]">
        <h3 className="text-secondary">Our Pricing Plans</h3>
        <h4 className="text-[1.4rem]">
          Select the Best Construction Plan for Your Business
        </h4>
      </div>
      <div className="max-w-screen-xl mx-auto px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16  font-bold">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2 sm:items-stretch md:grid-cols-2 md:gap-8">
          {/* <div className="divide-y bg-transparent rounded-2xl"></div> */}
          {/* Add the second plan here */}
          <div className="divide-y divide-gray-200 bg-white rounded-2xl border border-gray-200 drop-shadow-lg">
            <div className="p-6 sm:px-8">
              <h2 className="text-lg font-medium text-[#fafafa] rounded-full bg-secondary pl-6 w-32 drop-shadow-lg">
                Standard
                <span className="sr-only">Plan</span>
              </h2>
              <p className="mt-2 text-[#0C0E27]">
                Create and share professional interior plans in 2D and 3D.
              </p>
              <p className="mt-2 sm:mt-4">
                <strong className="text-3xl font-bold text-[#0C0E27] sm:text-4xl">
                  $299
                </strong>
                <span className="text-sm font-medium text-[#0C0E27]">
                  /month
                </span>
              </p>
              <p className="mt-2 sm:mt-4">
                {/* <strong className="text-1xl font-normal text-[#0C0E27] sm:text-2xl">
                  $299
                </strong> */}
                <span className="text-sm font-medium text-[#0C0E27]">
                  *one-time Initial setup fee: $149
                </span>
              </p>
            </div>
            <div className="p-6 sm:px-8">
              <p className="text-lg font-medium text-[#0C0E27] sm:text-xl">
                What's included:
              </p>
              <ul className="mt-2 space-y-2 sm:mt-4">
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span>
                    {" "}
                    Custom mobile apps for your (Clients & Sub-Contractors){" "}
                  </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Instant 3D and 2D building plans (floor plan). </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Receive, Payout & Manage finances </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span>
                    {" "}
                    Track Sub-Contractor's on-site activities on the go.{" "}
                  </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Chat with Clients & Sub-Contractors. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Easily generate invoices and estimate. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span>
                    {" "}
                    Review (Approve or Decline) estimates & Sign invoices
                    easily.{" "}
                  </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Receive and save projects via cloud. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Breakdown projects into task and assign tasks. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Create Bids, invite sub-contractors to bid. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> & more. </span>
                </li>
                {/* Add other list items here */}
              </ul>
              <Link to="/signup">
                <small className="mt-4 block rounded border border-secondary bg-secondary px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-secondary focus:outline-none focus:ring active:text-secondary sm:mt-6">
                  Get Started
                </small>
              </Link>
            </div>
          </div>

          {/* Premium Plan */}
          <div className="divide-y divide-gray-200 bg-white rounded-2xl border border-gray-200 drop-shadow-lg">
            <div className="p-6 sm:px-8">
              <h2 className="text-lg font-medium text-[#fafafa] rounded-full bg-secondary pl-6 w-32 drop-shadow-lg">
                Premium
                <span className="sr-only">Plan</span>
              </h2>
              <p className="mt-2 text-[#0C0E27]">
                Create and share professional interior plans in 2D and 3D.
              </p>
              <p className="mt-2 sm:mt-4">
                <strong className="text-3xl font-bold text-[#0C0E27] sm:text-4xl">
                  $399
                </strong>
                <span className="text-sm font-medium text-[#0C0E27]">
                  /month
                </span>
              </p>
              <p className="mt-2 sm:mt-4">
                {/* <strong className="text-1xl font-normal text-[#0C0E27] sm:text-2xl">
                  $299
                </strong> */}
                <span className="text-sm font-medium text-[#0C0E27]">
                  *one-time Initial setup fee: $149
                </span>
              </p>
            </div>
            <div className="p-6 sm:px-8">
              <p className="text-lg font-medium text-[#0C0E27] sm:text-xl">
                What's included:
              </p>
              <ul className="mt-2 space-y-2 sm:mt-4">
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span>
                    {" "}
                    Custom mobile apps for your (Clients & Sub-Contractors){" "}
                  </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Industry standard GANTT Chart. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Schedule Manager. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Quick books. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Instant 3D and 2D building plans (floor plan). </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Receive, Payout & Manage finances </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span>
                    {" "}
                    Track Sub-Contractor's on-site activities on the go.{" "}
                  </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Chat with Clients & Sub-Contractors. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Easily generate invoices and estimate. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span>
                    {" "}
                    Review (Approve or Decline) estimates & Sign invoices
                    easily.{" "}
                  </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Receive and save projects via cloud. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Breakdown projects into task and assign tasks. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> Create Bids, invite sub-contractors to bid. </span>
                </li>
                <li className="flex items-center gap-1 text-[#0C0E27]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-5 w-5 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span> & more. </span>
                </li>
                {/* Add other list items here */}
              </ul>
              <Link to="/signup">
                <small className="mt-4 block rounded border border-secondary bg-secondary px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-secondary focus:outline-none focus:ring active:text-secondary sm:mt-6">
                  Get Started
                </small>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
