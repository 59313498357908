import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import close from "../../assets/close.svg";
import menu from "../../assets/menu.svg";
import { navLinks } from "../../constants";

function Navigation() {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`h-24 w-${
          scrolling ? "full" : "95%"
        } mx-auto flex py-6 justify-around items-center navbar text-white font-bold fixed top-0 left-0 right-0 z-50 mb-11 ${
          scrolling ? "bg-primary" : "bg-primary"
        } transition-all duration-300 ease-in-out`}
      >
        <Link to="/">
          <h1 className="w-[124px] h-[32px] lg:ml-[6rem]  xs:ml-5 cursor-pointer">
            <span className="text-secondary text-5xl xs:text-3xl ">NAI</span>
            <span className="text-white text-5xl  xs:text-3xl ">LED</span>
          </h1>
        </Link>
        {/* <ul className="list-none md:flex hidden justify-end items-center flex-1 text-[1.7rem]">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`font-poppins font-bold cursor-pointer text-[1.4rem] mr-[3rem] ${
                active === nav.title ? "text-white" : "text-White"
              } ${
                index === navLinks.length - 1
                  ? "mr-0  px-5 py-1 font-extrabold text-[1.2rem] bg-secondary"
                  : "mr-[2.3rem] "
              }`}
              onClick={() => setActive(nav.title)}
            >
              <Link to={`${nav.id}`}>{nav.title}</Link>
            </li>
          ))}
        </ul> */}
        <ul className="list-none md:flex hidden justify-end items-center flex-1 text-[1.7rem]">
          {navLinks.map(
            (nav, index) =>
              // Check for medium devices and skip rendering "Home" link
              (window.innerWidth <= 768 || nav.id !== "/") && (
                <li
                  key={nav.id}
                  className={`font-poppins font-bold cursor-pointer text-[1.4rem] mr-[3rem] ${
                    active === nav.title ? "text-white" : "text-White"
                  } ${
                    (index === navLinks.length - 1 || index === navLinks.length - 2)
                      ? "mr-0 px-5 py-1 font-extrabold text-[1.2rem] bg-secondary rounded-full"
                      : "mr-[2.3rem] rounded-full"
                  }`} // Apply rounded-full className conditionally
                  onClick={() => setActive(nav.title)}
                >
                  <Link to={`${nav.id}`}>{nav.title}</Link>
                </li>
              )
          )}
        </ul>

        <div className="md:hidden flex flex-1 justify-end items-center ">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain mr-8"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-white text-[#0C0E27] absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col">
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-[#0C0E27]"
                  } ${index === navLinks.length ? "mb-0" : "mb-4"}`}
                  onClick={() => setActive(nav.title)}
                >
                  <Link to={`${nav.id}`}>{nav.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navigation;
