export const navLinks = [
  {
    id: "/",
    title: "Home",
  },
  // {
  //   id: "/estimate",
  //   title: "Estimate",
  // },
  // {
  //   id: "/report",
  //   title: "Report",
  // },
  // {
  //   id: "/claims",
  //   title: "Claims",
  // },
  // {
  //   id: "/pricing",
  //   title: "Pricing",
  // },
  {
    id: "https://cloud.nailed.biz/login",
    title: "Sign In To Nailed",
  },
  {
    id: "/signup",
    title: "Create Account",
  },
];