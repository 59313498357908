import React from 'react';
import floor2 from "./../../floor2.mp4";
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <main className="bg-black min-h-screen flex items-center justify-center">
      <article className="flex flex-col md:flex-row p-5 justify-center items-center max-w-7xl mx-auto text-[2rem]">
        <div className="text-center mb-12 md:mb-0 md:mr-6 lg:text-left">
          <h1 className="text-white text-4xl sm:text-5xl lg:text-6xl xl:text-7xl font-semibold leading-tight mt-40">
            The Desired{' '}
            <span className="text-secondary">App</span>
            <br />
            Contractors{' '}
            <span className="text-secondary">Love</span>
          </h1>
          <p className="text-white text-lg sm:text-xl lg:text-[1.2rem] mt-6 leading-7">
            The ideal app for nailing field reports, estimates and team collaboration on floor plans.
          </p>
          <div className="mt-10 flex flex-col gap-5">
            <div className="button-parent flex gap-5">
              <div className="px-5 py-3 bg-[#ff6c02] justify-center items-center flex rounded-full">
                <div className="text-white text-xl font-semibold">
                  <Link to="/signup">Get Started</Link>
                </div>
              </div>
              <div className="px-5 py-3 bg-white border-2 border-white justify-center items-center flex rounded-full">
                <div className="text-secondary text-xl font-semibold">
                  <Link to="/talktosales">Talk to Sales</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="aspect-w-16 aspect-h-9">
            <video
              src={floor2}
              autoPlay
              loop
              muted
              className="object-cover"
              playsInline
              type="video/mp4"
            />
          </div>
        </div>
      </article>
    </main>
  );
}

export default Hero;
