import React from 'react'
import { AiOutlineSync } from "react-icons/ai";
import { LuPencilRuler } from "react-icons/lu";
import { FaBuildingColumns, FaArrowRightLong } from "react-icons/fa6";
import sketchMain from "../../images/sketch/main-house-1.jpg"
import below1 from "../../images/sketch/below-1.1.png"
import below2 from "../../images/sketch/below-2.2.png"
import below3 from "../../images/sketch/house-below-3.jpg"

function Sketch() {
  return (
    <section className='bg-gray-900 overall lg:w-[100%] mx-auto pt-10 md:px-7 xl:w-[100%] h-max'>
    <article className="sketch-container flex flex-col md:flex lg:flex-row ">
      <div className="left-sketch  text-white w-[50%] xs:w-full md:w-full md:mx-auto xl:pr-[4rem]">  
        <h1 className='text-6xl xs:text-2xl md:text-5xl md:mb-6 lg:text-4xl xl:text-6xl leading-tight '>Integrated <span className='text-secondary xs:text-2xl md:text-5xl lg:text-4xl xl:text-6xl'>design</span>, engineering and <span className='text-secondary xs:text-2xl md:text-5xl lg:text-4xl xl:text-6xl' > <br className='hidden lg:inline-block'/> construction </span></h1>
        <p className='w-[520px] xs:w-full xs:text-[.9rem]  md:text-xl mb-5 text-base lg:text-sm xl:text-base xl:w-[520px]'>Explore futuristic design with our integrated 3D floor plans and 360-degree architectural visualization services. From concept to construction, we redefine architectural boundaries with creative excellence.</p>
        <ul>
            <li className='flex items-center text-sm mb-3'>
            <AiOutlineSync className=' mr-2 text-base' />
                <span className='xs:text-[.7rem] md:text-[1.1rem] lg:text-xs xl:text-sm'>3D FLOOR PLAN </span>
            </li>
            <li  className='flex items-center text-sm mb-3'>
                <LuPencilRuler  className='mr-2 '/>
                <span className='xs:text-[.7rem]  md:text-[1.1rem] lg:text-xs xl:text-sm'>COMBINED ARCHITECTURE AND ENGINEERING SERVICES </span>
            </li>
            <li  className='flex items-center text-sm mb-3'>
            <FaBuildingColumns  className='mr-2'/>
                <span  className='xs:text-[.7rem]  md:text-[1.1rem] lg:text-xs xl:text-sm'>FULL DESIGN AND BUILD SERVICE</span>
            </li>
        </ul>
         
         <a href="/gallery" className='bg-secondary flex items-center py-3 px-10 whitespace-nowrap w-[12rem] text-xs mt-11 rounded-md'>
            VIEW PROJECTS
            <FaArrowRightLong  className='ml-2 text-base md:text-3xl  text-white'/>
         </a>
        
        </div>

    <div className="right-sketch md:w-2/5  ml-24 xs:hidden lg:block xl:mr-[5rem]">
       <div className="image1 border lg:border-red-100 md:w-[27rem] lg:w-[21rem] xl:w-[31.3rem]">
        <img src={sketchMain} alt=""  className='w-[100%] '/>
       </div>
       <div className="image2 flex mt-2 gap-2 md:w-[39%]  lg:w-[31.8%] xl:w-[32.4%]">
        <img src={below1} width={161} className="sub-images " 
        alt="below1" />
        <img src={below2} width={161} className="sub-images"  alt="below2" />
        <img src={below3}  width={162} className="sub-images"  alt="below3" />
       </div>
        
    </div>
   </article>
   </section>
  )
}

export default Sketch