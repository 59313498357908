import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL_SALES;

export const useGetQuery = ({
  queryKey = [],
  url,
  enable = true,
}) => {

  return useQuery({
    queryKey,
    queryFn: async () => {
      const res = await axios.get(`${baseURL}${url}`);
      return res.data;
    },
    enabled: enable,
  });
};
