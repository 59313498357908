import axios from "axios";
import { useMutation } from "@tanstack/react-query";

const baseURL = process.env.REACT_APP_BASE_URL_SALES;

const useMutate = (mutationKey = []) => {

  return useMutation({
    mutationKey: [...mutationKey],
    mutationFn: async ({
      url,
      data = {},
      method = "POST",
      multipart = false,
      options = {
        headers: {},
        params: {},
      },
    }) => {
      const res = await axios({
        url: `${baseURL}${url}`,
        method,
        data,
        headers: {
          "Content-Type": multipart
            ? "multipart/form-data"
            : "application/json",
        },
        params: options.params,
      });

      //console.log("data:::", data, url, options)
      return res.data;
    },
  });
};

export default useMutate;
