import React from 'react'
import { FaWatchmanMonitoring, FaVault,FaMagnifyingGlassChart, 
    FaPeopleGroup, FaPlantWilt, FaShare } from "react-icons/fa6";

function Solution() {
  return (
    <section className="solution-containerz text-white mt-16 md:mt-24">
      <div className="solution-headings">
        <h1 className="text-center text-2xl font-bold">
          Meet Our Solution For You
        </h1>
        <div className="underline h-1 bg-secondary w-[5rem] mx-auto mt-2 mb-8"></div>
        <p className="text-center mb-11 font-bold">
          Discover Innovative Construction Solutions with Our 3D Floor Plan
          Design Services.
        </p>
      </div>

      <div className="solution-cards flex items-center justify-center flex-wrap md:flex-row gap-6 cursor-pointer ">
        <div className="solution-card md:w-[calc(45%-12px)] lg:w-[calc(30.3333%-12px)] w-[400px] h-[320px] border flex-col text-center p-5 hover:bg-gray-700">
          <div className="icon">
            <FaWatchmanMonitoring className="text-5xl xs:ml-[9.5rem] md:ml-[7rem]  lg:ml-[10.5rem] ml-40" />
          </div>
          <div className="contents-solution p-2 ">
            <h3 className="mt-5 text-2xl text-secondary">Project Monitoring</h3>
            <p className="mt-5">
              {" "}
              Gain real-time insights into project progress. Track tasks,
              timelines, and resource allocation. Ensure projects stay on course
              with our monitoring services.
            </p>
          </div>
        </div>
        <div className="solution-card md:w-[calc(45%-12px)] lg:w-[calc(30.3333%-12px)] w-[400px] h-[320px] border flex-col text-center p-5 hover:bg-gray-700">
          <div className="icon">
            <FaVault className="text-5xl xs:ml-[9.5rem]  md:ml-[7rem] lg:ml-[10.5rem] ml-40 " />
          </div>
          <div className="contents-solution p-2">
            <h3 className="mt-5 text-2xl text-secondary">Project Tracking</h3>
            <p className="mt-5">
              {" "}
              Ensure project success with real-time monitoring. Track all
              projects with precision for informed decisions.
            </p>
          </div>
        </div>
        <div className="solution-card md:w-[calc(45%-12px)] lg:w-[calc(30.3333%-12px)] w-[400px] h-[320px] border flex-col text-center p-5 hover:bg-gray-700">
          <div className="icon">
            <FaMagnifyingGlassChart className="text-5xl xs:ml-[9.5rem] lg:ml-[10.5rem] md:ml-[7rem] ml-40" />
          </div>
          <div className="contents-solution p-2">
            <h3 className="mt-5 text-2xl text-secondary">
              Manage Subcontractors
            </h3>
            <p className="mt-5">
              Efficiently manage subcontractors with transparent task
              tracking.Assign tasks, track performance and ensure collaboration
              is efficient and transparent.
            </p>
          </div>
        </div>
        <div
          className="solution-card md:w-[calc(45%-12px)] lg:w-[calc(30.3333%-12px)] w-[400px]
         h-[320px] border flex-col text-center p-5 hover:bg-gray-700"
        >
          <div className="icon">
            <FaPeopleGroup className="text-5xl xs:ml-[9.5rem]  md:ml-[7rem] lg:ml-[10.5rem] ml-40" />
          </div>
          <div className="contents-solution p-2">
            <h3 className="mt-5 text-2xl text-secondary">Client Mangement</h3>
            <p className="mt-5">
              Prioritize client satisfaction with our client management services
              and seamless communication exceeding expectations.
            </p>
          </div>
        </div>
        <div
          className="solution-card md:w-[calc(45%-12px)] lg:w-[calc(30.3333%-12px)] w-[400px] h-[320px] 
        border flex-col text-center p-5 hover:bg-gray-700"
        >
          <div className="icon">
            <FaPlantWilt className="text-7xl xs:ml-[9.5rem]  md:ml-[7rem] lg:ml-[10.5rem] ml-40" />
          </div>
          <div className="contents-solution p-2">
            <h2 className="mt-5 text-2xl text-secondary">
              Generate Floor Plan
            </h2>
            <p className="mt-5">
              {" "}
              Our 3D construction App transforms blueprints into life like 3D
              models, offering an immersive experience for stakeholders to
              explore architectural designs.
            </p>
          </div>
        </div>
        <div
          className="solution-card md:w-[calc(45%-12px)] lg:w-[calc(30.3333%-12px)] w-[400px] h-[320px]
         border flex-col text-center p-5 hover:bg-gray-700"
        >
          <div className="icon">
            <FaShare className="text-5xl xs:ml-[9.5rem]  md:ml-[7rem] lg:ml-[10.5rem] ml-40" />
          </div>
          <div className="contents-solution p-2">
            <h3 className="mt-5 text-2xl text-secondary">Payments</h3>
            <p className="mt-5">
              Make direct payments to construction companies or sub-contractors
              within the platform, streamlining financial transactions for
              efficient project management.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solution