import React from 'react'
import Navigation from '../Layout/Navigation';
import Footer2 from '../Layout/Footer2';

function BlogDetailsPage() {
   const backgroundStyle = {
     minHeight: "500px",
     backgroundImage: `url('/images2/blog-1.jpg')`,
     backgroundSize: "cover",
     backgroundPosition: "center",
   };


  return (
    <div>
      <Navigation />
      <div className="w-full mx-auto p-5 sm:p-10 md:p-16 relative bg-white mt-10">
        <div
          className="bg-cover bg-center text-center overflow-hidden"
          style={backgroundStyle}
          title="Woman holding a mug"
        ></div>
        <div className="max-w-3xl mx-auto">
          <div className="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
            <div className="bg-white relative top-0 -mt-32 p-5 sm:p-10">
              <h1 href="#" className="text-gray-900 font-bold text-3xl mb-2">
                Elevating Foundations: Exploring the Craft and Stories Behind
                Every Structure
              </h1>
              <p className="text-gray-700 text-xs mt-2">
                Written By:
                <a
                  href="#"
                  className="text-primary font-medium hover:text-gray-900 transition duration-500 ease-in-out"
                >
                  Prince Nartey
                </a>{" "}
                <a
                  href="#"
                  className="text-xs text-secondary  font-medium hover:text-gray-900 transition duration-500 ease-in-out"
                >
                  C.T.O Code Coast
                </a>
                ,
                <a
                  href="#"
                  className="text-xs text-secondary  font-medium hover:text-gray-900 transition duration-500 ease-in-out"
                >
                  Technology Company
                </a>
              </p>
              <p className="text-base leading-8 my-5">
                Embark on a captivating journey with our construction blog,
                where each entry serves as a unique chapter in the ongoing saga
                of architectural brilliance. "Elevating Foundations: Exploring
                the Craft and Stories Behind Every Structure" is not just a
                heading; it's an invitation to delve into the intricate world of
                construction mastery. In these narratives, we go beyond
                blueprints, unveiling the tales behind every brick and beam that
                shape our skyline.
              </p>
              <h3 className="text-2xl font-bold my-5">
                #1.Born out of uniqueness?
              </h3>
              <p className="text-base leading-8 my-5">
                From the genesis of groundbreaking projects to the meticulous
                craftsmanship woven into every structure, our blog is a
                testament to the passion, innovation, and dedication defining
                our architectural odyssey. Join us as we share the concrete
                conversations, the heartbeat of our construction endeavors, and
                illuminate the untold stories that breathe life into each
                project, creating a legacy that extends far beyond bricks and
                mortar. Welcome to a symphony of stories in construction, where
                every weld, every nail, and every word crafts a narrative of
                dreams realized and architectural marvels standing tall
              </p>
              <blockquote className="border-l-4 text-base italic leading-8 my-5 p-5 text-secondary ">
                From the groundbreaking commencement to the final finishing
                touches, each Project unravels the dedication, innovation, and
                relentless passion embedded in our work.
              </blockquote>
              <p className="text-base leading-8 my-5">
                Dive into our blog to witness the fusion of creativity and
                engineering, as we share the secrets behind each structure's
                rise. Beyond bricks and mortar, we are crafting a legacy that
                speaks of dreams fulfilled, challenges overcome, and a
                commitment to excellence that defines our construction
                endeavors. Join us in discovering the nuanced world of
                construction, where every entry is a page-turner in the epic
                tale of our architectural aspirations.
              </p>
              <a
                href="#"
                className="text-xs text-secondary font-medium hover:text-gray-900 transition duration-500 ease-in-out"
              >
                #floorplan
              </a>
              ,
              <a
                href="#"
                className="text-xs text-secondary  font-medium hover:text-gray-900 transition duration-500 ease-in-out"
              >
                #construction
              </a>
              ,
              <a
                href="#"
                className="text-xs text-secondary  font-medium hover:text-gray-900 transition duration-500 ease-in-out"
              >
                #AI
              </a>
              ,
              <a
                href="#"
                className="text-xs text-secondary  font-medium hover:text-gray-900 transition duration-500 ease-in-out"
              >
                #nailed
              </a>
              ,
              <a
                href="#"
                className="text-xs text-secondary  font-medium hover:text-gray-900 transition duration-500 ease-in-out"
              >
                #technews
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}

export default BlogDetailsPage