import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';
import aboutImg from '../../images/about_img2.png';

function About() {
  return (
    <section className="about-parent mt-32 mb-16 min-h-screen bg-gray-900 flex justify-center items-center">
      <div className="section-row flex flex-col md:flex-row justify-center items-center w-full max-w-7xl mx-auto px-4">
        <div className="section-row-image w-full md:w-1/2 md:mr-12">
          <img src={aboutImg} alt="" className="w-full" />
          <Link to="/talktosales">
            <button className="bg-secondary mt-8 md:mt-12 px-8 py-3 flex items-center font-semibold text-white rounded-3xl">
              Talk to Sales
              <FaArrowRightLong className="ml-5" />
            </button>
          </Link>
        </div>
        <div className="section-row-content w-full md:w-1/2">
          <Link to="/about">
          <h2 className="text-4xl lg:text-5xl xl:text-6xl text-white mb-5">
            About <span className="text-secondary">Nailed</span>
          </h2>
          </Link>
          <div className="underline2"></div>
          <p className="text-[15px] md:text-[13px] lg:text-lg lg:leading-[35px] text-white xl:text-[20px] font-semibold leading-[35px] mb-5">
            Nailed construction, a gateway to a new dimension of construction management. Take a virtual tour of our platform, designed to revolutionize the way you plan, visualize, and execute construction projects. Streamline decision-making, enhance project efficiency, and bring your construction visions to life like never before.
          </p>
          <p className="hidden md:block text-white text-[20px] font-semibold mb-5">
            This is where precision meets imagination and reality meets possibility.
          </p>
          <Link to="/signup">
            <button className="bg-secondary mt-8 md:mt-12 px-12 py-3 items-center font-semibold text-white rounded-md">
              Talk to Sales
              <FaArrowRightLong className="ml-2" />
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default About;
