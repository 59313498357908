import React from 'react'
import { Link } from 'react-router-dom';
import Navigation from "../../Layout/Navigation";
import Footer2 from "../../Layout/Footer2";
import estimate from "../../../../src/Project & Work Management Software.mp4";
import Blog from '../../Layout/Blog';
import Testimonials from '../../Layout/Testimonials';

function ReportNav() {
  return (
    <main className="max-w-full mx-auto">
      <Navigation />
      <section className="pt-32 max- w-full ">
        <header className="grid grid-cols-12 items-center gap-5 px-5 md:px-10 mx-8">
          <div className="col-span-12 md:col-span-6">
            <h1 className="text-white text-4xl lg:text-[53.02px] md:text-[29.02px] font-bold font-['Raleway']  leading-[50px] md:leading-[40px] lg:leading-[70px] my-5">
              Crafting Project Specific Solutions with expertise
            </h1>
            <div className=" text-neutral-400 text-lg font-medium font-['Raleway'] leading-[27px]">
              <h3>
                We are a construction company that focuses on establishing long
                term relationship with customers.
              </h3>
              <p>
                Structured, customized reports sent to your clients directly
                from the app
              </p>
            </div>
            <div className="wrapper flex xs:flex-col gap-5 mb-16">
              <div className="button-parent flex  mt-8 gap-5 ">
                <div className=" px-6 lg:px-12 py-3 bg-[#ff6c02] justify-center items-center gap-2.5 inline-flex ">
                  <div className="text-white text-xl font-semibold font-['Raleway']">
                    <Link to="/signup">Get Started</Link>
                  </div>
                </div>
                <div className=" px-6 lg:px-12 py-3  border-2 border-white justify-center items-center gap-2.5 inline-flex">
                  <div className="text-white text-xl font-semibold font-['Raleway']">
                    <Link to="/talktosales">Talk to sales</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12 md:col-span-6 hidden md:block">
            <img className="w-full" src="/images2/hero-slide-1.jpg" />
          </div>
        </header>

        <div className=" w-screen bg-black">
          <div className="mx-auto max-w-full pt-16 sm:pt-24">
            <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                <div className="space-y-8">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <span className="rounded-full uppercase bg-secondary px-3 py-0.5 text-sm mb-7 font-semibold leading-5 text-white">
                        Why Choose Us
                      </span>
                      <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-[29.02px] pt-[2rem]">
                        We bring solutions to make life easier for our clients.
                      </h1>
                    </div>

                    <p className="text-lg text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Experience a revolutionary approach to construction
                      reporting with Nailed. Our advanced 3D virtual
                      construction app not only transforms blueprints into
                      lifelike 3D models but also provides detailed and
                      insightful reports. Dive into a new dimension of
                      construction management, where comprehensive information
                      meets immersive visualization.
                    </p>
                  </div>

                  <div className="border-t border-gray-700"></div>
                </div>
              </div>

              <div className="flex items-center w-full col-span-6 pt-[3rem]">
                <div className="px-6 h-96 lg:h-100% w-full max-w-2xl col-span-6 flex items-center mx-auto">
                  <div style={{ width: "100%", height: "100%" }}>
                    <div style={{ width: "100%", height: "100%" }}>
                      <img className="w-full" src="/images2/about-banner.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" w-screen bg-black pt-28">
          <div className="mx-auto max-w-full sm:pt-24">
            <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                <div className="space-y-8">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <span className="rounded-full uppercase bg-secondary px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                        Timely Reports
                      </span>
                      <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-[29.02px] md:leading-[40px] pt-[2rem]">
                        Revolutionary Construction Reports Platform Transforming
                        Data Beyond the Blueprint
                      </h1>
                    </div>

                    <p className="text-base text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Explore a sneak peek into our groundbreaking construction
                      app, transcending traditional data handling. Beyond the
                      blueprint, our platform delivers transformative insights
                      for efficient project management. Elevate your
                      construction experience with advanced reporting
                      capabilities.
                    </p>
                  </div>

                  <div className="border-t border-gray-700 pt-[2rem] pb-[2rem]"></div>

                  <div className="flex space-x-4 items-center text-white">
                    <div className="flex items-center space-x-2">
                      <div className="flex flex-shrink-0 -space-x-1">
                        <img
                          loading="lazy"
                          width="400"
                          height="400"
                          decoding="async"
                          className="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                          style={{ color: "transparent" }}
                          src="https://randomuser.me/api/portraits/men/29.jpg"
                        />
                        <img
                          loading="lazy"
                          width="400"
                          height="400"
                          decoding="async"
                          className="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                          style={{ color: "transparent" }}
                          src="https://randomuser.me/api/portraits/men/90.jpg"
                        />
                        <img
                          loading="lazy"
                          width="100"
                          height="100"
                          decoding="async"
                          className="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                          style={{ color: "transparent" }}
                          src="https://randomuser.me/api/portraits/men/75.jpg"
                        />
                        <img
                          loading="lazy"
                          width="200"
                          height="200"
                          decoding="async"
                          className="h-6 w-6 max-w-none rounded-full ring-2 ring-white"
                          style={{ color: "transparent" }}
                          src="https://randomuser.me/api/portraits/men/5.jpg"
                        />
                      </div>

                      <span className="flex-shrink-0 text-xs font-medium leading-5">
                        +15
                      </span>
                    </div>

                    <div className="h-4 border-l border-gray-700"></div>

                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 fill-current text-yellow-500"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"></path>
                      </svg>
                      <svg
                        className="h-4 w-4 fill-current text-yellow-500"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"></path>
                      </svg>
                      <svg
                        className="h-4 w-4 fill-current text-yellow-500"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"></path>
                      </svg>
                      <svg
                        className="h-4 w-4 fill-current text-yellow-500"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"></path>
                      </svg>
                      <svg
                        className="h-4 w-4 fill-current text-yellow-500"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"></path>
                      </svg>
                    </div>
                    <div className="h-4 border-l border-gray-700"></div>
                  </div>
                </div>
              </div>

              <div className="flex items-center w-full col-span-6">
                <div className="px-6 h-96 lg:h-100% w-full max-w-2xl col-span-6 flex items-center mx-auto">
                  <div style={{ width: "100%", height: "100%" }}>
                    <div style={{ width: "100%", height: "100%" }}>
                      <video
                        src={estimate}
                        autoPlay="autoPlay"
                        loop
                        muted
                        className="w-full"
                        playsInline={true}
                        type="video/mp4"
                      ></video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-screen w-screen bg-black pt-28">
          <div className="mx-auto max-w-full pt-16 sm:pt-24">
            <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                <div className="space-y-8">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <span className="rounded-full uppercase bg-secondary px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                        OUR SERVICES
                      </span>
                      <h1 className="text-4xl font-bold tracking-loose text-white sm:text-5xl md:text-[29.02px] md:leading-[40px] pt-[2rem]">
                        We make your spending stress-free for you to have the
                        perfect control.
                      </h1>
                    </div>

                    <p className="text-base text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Nailed is a gateway to the new dimension of construction
                      management . Our 3D virtual construction app transforms
                      blueprints into life-like 3D models, offering an immersive
                      experience for stakeholders to explore architectural
                      designs.
                    </p>
                  </div>

                  <div className="border-t border-gray-700"></div>
                </div>
              </div>

              <div className="flex items-center w-full col-span-6 pt-[1rem] pb-[4rem]">
                <div className="px-6 h-96 lg:h-100% w-full max-w-2xl col-span-6 flex items-center mx-auto">
                  <div style={{ width: "100%", height: "100%" }}>
                    <div style={{ width: "100%", height: "100%" }}>
                      <img
                        className="w-full"
                        src="/images2/feature-banner.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <Blog />
    </main>
  );
}

export default ReportNav;
