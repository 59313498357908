import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../../Layout/Navigation";
import Testimonials from "../../Layout/Testimonials";
import Footer2 from "../../Layout/Footer2";
import Blog from "../../Layout/Blog";

function Claims() {
  return (
    <>
      <Navigation />
      {/* <section className="pt-32 w-[95%] mx-auto bg-black"> */}
      <header className="grid grid-cols-12 gap-5 px-5 md:px-10 pt-40 w-[95%] mx-auto ">
        <div className="col-span-12 md:col-span-6">
          <div className=" text-[#ff6c02] lg:text-xl font-semibold font-['Raleway'] xs:text-sm">
            WELCOME TO NAILED
          </div>
          <h3 className="text-white text-4xl lg:text-[63.02px] font-bold font-['Raleway'] leading-[45px] lg:leading-[80px] my-5">
            Prompt Claims With Perfect Response
          </h3>
          <div className=" text-neutral-400 text-base font-medium font-['Raleway'] leading-[27px]">
            Swift and precise claims processing, delivering an immediate and
            flawless response <br className="hidden xs:block" />
            to meet your needs.
          </div>
          <div className="wrapper flex flex-col gap-5 mb-16">
            {/* ********************** */}
            {/* buttons */}
            <div className="button-parent flex mt-5 gap-5">
              <div className=" px-5 lg:px-10 py-3 bg-[#ff6c02] justify-center items-center gap-2.5 inline-flex ">
                <div className="text-white text-xl font-semibold font-['Raleway']">
                  <Link to="/signup"> Get Started</Link>
                </div>
              </div>
              <div className=" px-5 lg:px-10 py-3  border-2 border-white justify-center items-center gap-2.5 inline-flex">
                <div className="text-white text-xl font-semibold font-['Raleway']">
                  <Link to="/talktosales">Talk to sales</Link>
                </div>
              </div>
            </div>

            <article className="flex gap-10 lg:mt-5">
              <div className=" ">
                <div className=" ">
                  <div className=" text-white text-3xl lg:text-[63px] font-medium font-['Raleway']">
                    800
                  </div>
                </div>
                <div className=" top-[88px]  text-neutral-400 mt-3 text-base font-semibold font-['Raleway']">
                  Positive Reviews
                </div>
              </div>
              <div className="">
                <div className=" ">
                  <div className="   text-white text-3xl lg:text-[63px] font-medium font-['Raleway']">
                    550
                  </div>
                </div>
                <div className="   text-neutral-400 mt-3 text-base font-semibold font-['Raleway']">
                  Active Customers
                </div>
              </div>
            </article>
          </div>

          {/* REVIEWS */}
        </div>

        <div className="col-span-12 md:col-span-6">
          <img className="w-full" src="/images/student.png" />
        </div>
      </header>

      {/* header ends */}

      <section className="grid md:justify-center md:items-center grid-cols-12 mt-[10rem] xs:mt-[6rem] gap-8 lg:gap-0 md:px-4 pt-40 w-100 mx-auto bg-black pb-[5rem]">
        <div className=" relative  col-span-12 md:col-span-6 hidden md:block md:ml-4 ml-[4rem]">
          <div className="w-[578px] lg:w-[578px] lg:h-[567px] h-[567px] md:w-[350px] md:h-[370px] bg-[#dedbda] rounded-full md:block"></div>
          <img
            className=" top-[-5rem] left-5 lg:left-[3rem] absolute"
            src="/images/arabguy.png"
          />
        </div>

        <div className="col-span-12 md:col-span-6  p-5 lg:ml-20 md:ml-7 xl:ml-0">
          <div className="text-[#ff6c02] text-xl mb-[2rem] font-semibold font-['Raleway']">
            ABOUT NAILED
          </div>
          <div className=" text-white text-3xl font-bold font-['Raleway'] lg:text-[55.02px] mr-8 leading-[40px] lg:leading-[80px]">
            Experience streamlined claims processing with Nailed
          </div>
          <div className=" text-neutral-400 text-base  font-medium font-['Raleway'] leading-[27px] mt-2">
            Optimize claims accuracy and efficiency for your construction
            projects with our specialized app.
          </div>
          <div className="contents">
            <div className="left flex gap-10 mt-5 mb-5">
              <div className=" text-[#ff6c02] text-base font-semibold font-['Raleway']">
                Faster Process
              </div>
              <div className=" text-[#ff6c02] text-base font-semibold font-['Raleway']">
                Faster Delivery
              </div>
            </div>
            <div className="right flex gap-10  mt-5 mb-5">
              <div className=" text-[#ff6c02] text-base font-semibold font-['Raleway']">
                Faster Access
              </div>
              <div className=" text-[#ff6c02]  text-base font-semibold font-['Raleway'] xs:ml-2">
                24/7 Full Support
              </div>
            </div>
          </div>
          <div className="px-[4rem] py-[1rem]  bg-[#ff6c02] mt-8 justify-center items-center gap-2.5 inline-flex">
            <div className="text-white text-xl font-semibold font-['Raleway']">
              Discover More
            </div>
          </div>
        </div>
      </section>
      {/* ******************************************* */}

      <section className="grid grid-cols-12 gap-5 items-center px-5 md:px-10 pt-[12rem] xs:pt-[7rem]">
        <div className="col-span-12 md:col-span-6">
          <div className=" text-[#ff6c02] lg:text-xl font-semibold font-['Raleway'] xs:text-sm">
            GET STARTED
          </div>
          <h3 className="text-white text-4xl lg:text-[70.02px] font-bold font-['Raleway'] leading-[50px] lg:leading-[80px] my-5">
            Let's Start Here! Bring Your Claims
          </h3>
          <div className=" text-neutral-400 text-base font-medium font-['Raleway'] leading-[27px]">
            Embark on your construction journey with us – initiate the process
            by presenting your claims <br className="hidden md:block" />
            and let us bring your vision to life.
          </div>
        </div>

        <div className="col-span-12 md:col-span-6">
          <img className="w-full" src="/images/blackman.png" />
        </div>
      </section>
      {/* ************************************* */}
      <Blog />
      <div className="mt-[7rem]">
        <Footer2 />
      </div>
    </>
  );
}

export default Claims;
