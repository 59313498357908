import React from "react";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <div className="relative bg-gray-50 px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3"></div>
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-primary sm:text-4xl">
            Building Tomorrow's World
          </h2>
          <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
            Explore innovative construction insights shaping the future.
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1679&amp;q=80"
                alt=""
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <Link to="/blogdetailspage">
                  <p className="text-sm font-medium text-secondary">
                    <a className="hover:underline">Article</a>
                  </p>
                  <a className="mt-2 block">
                    <p className="text-xl font-semibold text-primary">
                      {" "}
                      Unveiling the Architectural Marvel
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      In our latest venture, we delve into the intricacies of
                      our recently completed residential project, showcasing the
                      fusion of modern design principles with timeless
                      craftsmanship. Explore the details that went into crafting
                      a living space that not only meets but exceeds the
                      expectations of contemporary homeowners.
                    </p>
                  </a>
                </Link>
              </div>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/blogdetailspage">
                    <a>
                      <span className="sr-only"></span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                        alt=""
                      />
                    </a>
                  </Link>
                </div>
                <div className="ml-3">
                  <Link to="/blogdetailspage">
                    <p className="text-sm font-medium text-primary">
                      <a className="hover:underline">Rafik Abubakari</a>
                    </p>
                  </Link>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime="2020-03-16">Nov 29, 2023</time>
                    <span aria-hidden="true">·</span>
                    <span>6 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                src="https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1679&amp;q=80"
                alt=""
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <Link to="/blogdetailspage">
                  <p className="text-sm font-medium text-secondary">
                    <a className="hover:underline">Blog</a>
                  </p>
                  <a className="mt-2 block">
                    <p className="text-xl font-semibold text-primary">
                      Redefining urban living
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      Embark on a tour of our ambitious commercial project that
                      stands as a testament to urban innovation. Uncover the
                      thought process behind creating a dynamic space that
                      fosters collaboration, productivity, and a unique
                      aesthetic appeal.
                    </p>
                  </a>
                </Link>
              </div>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/blogdetailspage">
                    <a>
                      <span className="sr-only"> Eunice Acheampong</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                        alt=""
                      />
                    </a>
                  </Link>
                </div>
                <div className="ml-3">
                  <Link to="/blogdetailspage">
                    <p className="text-sm font-medium text-primary">
                      <a className="hover:underline">Eunice Acheampong</a>
                    </p>
                  </Link>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime="2020-03-10">Sep 10, 2023</time>
                    <span aria-hidden="true">·</span>
                    <span>4 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover"
                src="https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1679&amp;q=80"
                alt=""
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <Link to="/blogdetailspage">
                  <p className="text-sm font-medium text-secondary">
                    <a className="hover:underline">Article</a>
                  </p>
                  <a className="mt-2 block">
                    <p className="text-xl font-semibold text-primary">
                      Improve your customer experience
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      Delve into the meticulous restoration of a historic
                      landmark, where the essence of the past meets the demands
                      of the present.Unmask the challenges faced and triumphs
                      achieved in the delicate process of preserving
                      architectural heritage while ensuring structural
                      integrity.
                    </p>
                  </a>
                </Link>
              </div>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/blogdetailspage">
                    <a>
                      <span className="sr-only">Sarah Kinful</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                        alt=""
                      />
                    </a>
                  </Link>
                </div>
                <div className="ml-3">
                  <Link to="/blogdetailspage">
                    <p className="text-sm font-medium text-primary">
                      <a className="hover:underline">Sarah Kinful</a>
                    </p>
                  </Link>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime="2020-02-12">July 12, 2023</time>
                    <span aria-hidden="true">·</span>
                    <span>11 min read</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
