import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Pricing from "./Pages/Pricing/Pricing";
import TalkToSales from "./Pages/TalkToSales";
import Home from "./Layout/Home";
import Report from "./Pages/report/Report";
import Claims from "./Pages/claimsPage/Claims";
import Estimate from "./Pages/Estimate";
import Details from "./Pages/Details";
import Signup from "./Pages/Signup";
import Login from "./Pages/Login";
import Gallery from "./Pages/Gallery";
import BlogDetailsPage from "./Pages/BlogDetailsPage";
import TAndCPage from "./Pages/TAndCPage";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";
function Markup() {
	return (
		<BrowserRouter>
			<div className="page-wraper">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/report" element={<Report />} />
					<Route path="/claims" element={<Claims />} />
					<Route path="/estimate" element={<Estimate />} />
					<Route path="/talktosales" element={<TalkToSales />} />
					<Route path="/pricing" element={<Pricing />} />
					<Route path="/details" element={<Details/>} />
					<Route path="/login" element={<Login/>} />
					<Route path="/signup" element={<Signup/>} />
					<Route path="/gallery" element={<Gallery/>} />
					<Route path="/blogdetailspage" element={<BlogDetailsPage/>} />
					<Route path="/terms-and-conditions" element={<TAndCPage />} />
					<Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
				</Routes>
			</div>
		</BrowserRouter>
	);
}

export default Markup;
