import React from 'react'
import Navigation from '../Layout/Navigation'
import Blog from '../Layout/Blog'
import Footer2 from '../Layout/Footer2'
import { Link } from 'react-router-dom'

function Details() {
  return (
    <>
      <Navigation />
      <section className="mt-[10rem]">
        <div className="mx-auto max-w-[95%] px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
            <div className="relative z-10 lg:py-16">
              <div className="relative h-64 sm:h-80 lg:h-full">
                <img
                  alt="House"
                  src="/gallery/mansion.jpg"
                  className="absolute inset-0 h-full w-full object-cover"
                />
              </div>
            </div>

            <div className="relative flex items-center bg-gray-100">
              <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-gray-100"></span>

              <div className="p-8 sm:p-16 lg:p-24">
                <h2 className="text-2xl font-bold sm:text-3xl text-gray-600">
                  Cityscape Residences - Transformative Living Spaces.
                </h2>

                <p className="mt-4 text-gray-600">
                  Welcome to the detailed showcase of our premier project,
                  "Cityscape Residences Immerse yourself in the journey of
                  crafting extraordinary living spaces within the heart of New
                  York. From inception to completion, explore the meticulous
                  construction process that defines our commitment to quality
                  and innovation.
                </p>
                <ul className="mt-4 text-primary font-bold">
                  <li>
                    {" "}
                    <span className="text-secondary">Project Name</span> :
                    Cityscape Estates
                  </li>
                  <li className="mt-5">
                    {" "}
                    <span className="text-secondary">Location</span> : Newark,
                    New York
                  </li>
                  <li className="mt-5">
                    {" "}
                    <span className="text-secondary">Contractor</span> : Nailed
                    Construction Company Limited
                  </li>
                </ul>
                <Link to="/talktosales">
                  <a className="mt-8 inline-block rounded border border-orange-500 bg-orange-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-orange-500 focus:outline-none focus:ring active:text-indigo-500">
                    Get in Touch
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </>
  );
}

export default Details