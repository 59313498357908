import React from "react";
import { Link } from "react-router-dom";

function Features() {
  return (
    <section className="overflow-hidden bg-white py-8 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-primary">
                Generate Floor Plans Faster
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-secondary sm:text-4xl">
                App Features
              </p>
              <p className="mt-6 text-lg leading-8 text-primary">
                We've built an App that allows you to scale your construction
                production workflow.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    Monitoring subcontractors: {""}
                  </dt>
                  <dd className="inline">
                    The App allows us to efficiently oversee subcontractors to
                    ensure seamless project execution and quality outcomes.
                  </dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    Tracking subcontractors: {""}
                  </dt>
                  <dd className="inline">
                    Systematically monitoring and tracking subcontractors for
                    project progress and quality assurance.
                  </dd>
                </div>
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    Client management: {""}
                  </dt>
                  <dd className="inline">
                    Nurturing strong client relationships through effective
                    communication, tailored solutions, and proactive service
                    management.
                  </dd>
                </div>
              </dl>
              <div className="mt-10 flex items-center gap-x-6">
                <Link to="/signup">
                  <button className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Start for free
                  </button>
                </Link>
                <Link to="/talktosales">
                  <button className="text-sm font-semibold leading-6 bg-secondary text-white rounded-md px-3.5 py-2.5">
                    Schedule a demo
                    <span aria-hidden="true">→</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <img
            src="https://images.pexels.com/photos/3184325/pexels-photo-3184325.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="Product screenshot"
            className="w-full h-auto max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-full md:-ml-4 lg:-ml-0 object-cover bg-center"
          />
        </div>
      </div>
    </section>
  );
}

export default Features;
