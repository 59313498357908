import React from "react";
import Navigation from "./Navigation";
import Footer2 from "./Footer2";
import Hero from "./Hero";
import Sketch from "./Sketch";

import Solution from "./Solution";
import Practice from "./Practice"
import About from "./About";
import Testimonials from "./Testimonials";
import Banner from "./Banner";
import Project2 from "./Project2";
import Playstore from "./Playstore";
import HowItWorks from "./HowItWorks";
import Features from "./Features";
import Blog from "./Blog";
import Steps from "./Steps";
import FeatureList from "./FeatureList";
import Pricing from "../Pages/Pricing/Pricing";
function Home() {
	return (
    <div>
      {/* <Practice/> */}
      <Navigation />
      <Hero />
      <Pricing />
      {/* <Sketch /> */}
      <Solution />
      <About />
      <Steps/>
      <HowItWorks />
      
      <Features />
      
      {/* <Project2 /> */}
      {/* <FeatureList /> */}
      <Playstore />
      {/* <Testimonials/> */}
      {/* <Banner /> */}
      {/* <Blog/> */}
      <Footer2 />
    </div>
  );
}

export default Home;
