import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import aboutImg from "../../images/about_img2.png";

function PrivacyPolicyPage() {
  return (
    <section className="about-parent mt-32 mb-16">
      <div className="section-row flex flex-col md:flex-row justify-center items-center gap-12 w-[85%] mx-auto">
        {/* <div className="section-row-image w-[50%] hidden md:block ">
          <img src={aboutImg} alt="" />
          <Link to="/talktosales">
            <button className="bg-secondary  mt-12 md:px-8 px-16 py-3 flex md:text-sm md:ml-12 items-center font-semibold text-white rounded-3xl">
              Talk to Sales
              <FaArrowRightLong className="ml-5" />
            </button>
          </Link>
        </div> */}
        <div className="section-row-content xs:w-full  w-[100%] md:w-full">
          <h2 className=" xs:text-4xl lg:text-5xl xl:text-6xl text-6xl text-white mb-5">
            <span className="text-secondary">
              LeMarini Construction Privacy Policy
            </span>{" "}
          </h2>
          <div className="underline2"></div>
          <p className=' xs:text-[15px] md:text-[13px] lg:text-lg lg:leading-[35px] text-white xl:text-[20px] text-[20px] font-semibold font-["Poppins"] leading-[35px]'>
            Privacy Policy for LeMarini Construction App
            <p> Effective Date: 24th January 2024 </p>
            <p>
              LeMarini Construction ("we," "us," or "our") is committed to
              protecting the privacy of our users. This Privacy Policy outlines
              how we collect, use, disclose, and safeguard your personal
              information when you use the LeMarini Construction mobile
              application (the "App"). By using the App, you consent to the
              practices described in this Privacy Policy.{" "}
            </p>
            <p>
              **1. Information We Collect:** We may collect the following types
              of information: - **Personal Information:** This may include your
              name, contact information, and other identifiers necessary for
              providing our services. - **Project Data:** Information related to
              client projects, subcontractor bids, job site monitoring, and
              payment details entered into the App. - **Usage Data:**
              Information about your interactions with the App, such as log
              files, device information, and usage patterns.{" "}
            </p>
            <p>
              **2. How We Use Your Information:** We use the collected
              information for the following purposes: - **Providing Services:**
              To deliver the features and functionalities of the App, including
              managing client projects, subcontractor bids, job site monitoring,
              and payments. - **Improving User Experience:** To analyze usage
              patterns and enhance the App's performance, features, and user
              interface. - **Communications:** To respond to inquiries, provide
              updates, and send relevant information about our services. -
              **Legal Compliance:** To comply with applicable laws and
              regulations.{" "}
            </p>
            <p>
              **3. Information Sharing and Disclosure:** We do not sell, trade,
              or otherwise transfer your personal information to outside
              parties. However, we may share your information in the following
              circumstances: - **Third-Party Service Providers:** We may use
              trusted third-party services to assist in the operation of the App
              and related services. - **Legal Compliance:** We may disclose your
              information to comply with legal obligations or in response to
              lawful requests from public authorities.
            </p>
            <p>
              **4. Data Security:** We implement security measures to protect
              your personal information from unauthorized access, disclosure,
              alteration, and destruction. However, no method of transmission
              over the internet or electronic storage is 100% secure, and we
              cannot guarantee absolute security.
            </p>
            <p>
              **5. Your Choices:** You can choose not to provide certain
              information, but this may limit your ability to use specific
              features of the App. You may also adjust your device settings to
              control the sharing of location information.{" "}
            </p>
            <p>
              **6. Updates to This Privacy Policy:** We may update this Privacy
              Policy periodically. Any changes will be posted on this page, and
              the effective date will be updated accordingly. Your continued use
              of the App after any changes indicate your acceptance of the
              revised Privacy Policy.{" "}
            </p>
            <p>
              **7. Contact Us:** If you have any questions or concerns about
              this Privacy Policy, please contact us at contact@nailed.biz.
              Thank you for choosing LeMarini Construction. We are committed to
              safeguarding your privacy and providing you with a secure and
              efficient construction management experience.
            </p>
          </p>
          {/* <p className=' xs:hidden text-white text-[20px] font-semibold font-["Poppins"]'>
            This is where precision meets imagination and reality meets
            possibility.
          </p> */}
          <Link to="/signup">
            <button className="bg-secondary xl:mt-12 mt-8 px-12 py-3 items-center font-semibold text-white rounded-md hidden lg:border lg:ml-[4rem]">
              Talk to Sales
              <FaArrowRightLong />
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicyPage;
