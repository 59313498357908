import React from 'react'
import ReportNav from './ReportNav'
import Navigation from '../../Layout/Navigation'
import Footer2 from '../../Layout/Footer2'

function Report() {
  return (
    <div>
        <Navigation/>
        <ReportNav/>
        <Footer2/>
    </div>
  )
}

export default Report