import React from 'react'
import { Link } from 'react-router-dom';
import Navigation from '../Layout/Navigation'
import Footer2 from '../Layout/Footer2'

function Gallery() {
  return (
    <main>
      <Navigation />
      <div className="text-center  pt-[10rem] mb-2">
        <h5 className="text-secondary font-bold text-2xl">Our Projects </h5>
        <h4 className="text-white font-bold text-2xl mb-8">
          Take a tour through some of our wonderful projects
        </h4>
      </div>
      <div className="flex flex-col md:grid md:grid-cols-3 gap-3 w-[90%] mx-auto  mb-36">
        <div className="relative rounded overflow-hidden">
          <img
            src="/gallery/residential.jpeg"
            alt="Hanging Planters"
            className="w-full"
          />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Getty Estates
            </p>
          </Link>
        </div>
        <div className="relative rounded overflow-hidden">
          <img
            src="/gallery/2D-Floor-Plan.jpg"
            alt="Planter Stand with Pots"
            className="w-full"
          />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Samid Office Floor Plan
            </p>
          </Link>
        </div>
        <div className="relative rounded overflow-hidden">
          <img
            src="/gallery/3D-Floor-Plan.jpg"
            alt="Watering Cans"
            className="w-full"
          />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Nailed Designs
            </p>
          </Link>
        </div>
        <div className="relative rounded overflow-hidden">
          <img src="/gallery/mall.jpeg" alt="Metal Planters" className="w-full" />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Hanging Planters
            </p>
          </Link>
        </div>
        <div className="relative rounded overflow-hidden">
          <img
            src="/gallery/hotel.jpeg"
            alt="Table Top Planters"
            className="w-full"
          />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Manhattan City Mall
            </p>
          </Link>
        </div>
        <div className="relative rounded overflow-hidden">
          <img
            src="/gallery/hospital.webp"
            alt="Wall Mounted Stands"
            className="w-full"
          />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Mayo Hospital
            </p>
          </Link>
        </div>
        <div className="relative rounded overflow-hidden">
          <img
            src="/gallery/project1.png"
            alt="Jute Plant Pots"
            className="w-full"
          />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Superior Plaza
            </p>
          </Link>
        </div>
        <div className="relative rounded overflow-hidden">
          <img src="/gallery/project2.png" alt="Bird Feeders" className="w-full" />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Cropton Heights
            </p>
          </Link>
        </div>
        <div className="relative rounded overflow-hidden">
          <img src="/gallery/project3.png" alt="Hanging Birds" className="w-full" />
          <Link to="/details">
            <p className="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
              Vanilla Villa's
            </p>
          </Link>
        </div>
      </div>
      <Footer2 />
    </main>
  );
}

export default Gallery