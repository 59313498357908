import React from "react";
import Markup from "./markup/Markup";

function App() {
	return (
		<>
			<div>
				<Markup />
			</div>
		</>
	);
}

export default App;
