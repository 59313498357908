import React from "react";
import Blog from "../Layout/Blog";
import Navigation from "../Layout/Navigation";
import Footer2 from "../Layout/Footer2";
import estimate from "./../../Project & Work Management Software.mp4";
import { Link } from "react-router-dom";
import lady from "../../images/estimate-Software.png";
import quick from "../../images/quick-estimates.png";
import blog3 from "../../images/Blogpost_content__3_.png";

function Estimate() {
  return (
    <>
      <Navigation />
      <section className="mt-[3rem] ">
        <section
          className="relative bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage:
              "url(https://media.licdn.com/dms/image/D5612AQFudWYepoG2cg/article-cover_image-shrink_720_1280/0/1677217069974?e=1707350400&v=beta&t=TU04mL01Y36Zk9PDCJ3Bgep15w9IkqwwX2hpZ2lXv-o)",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative ml-8 max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8 ">
            <div className="max-w-xl text-start ltr:sm:text-left rtl:sm:text-right">
              <h1 className="text-3xl font-bold sm:text-5xl text-white">
                Create Precise Estimates
                <strong className="block font-bold text-secondary leading-[80px]">
                  On the Go.
                </strong>
              </h1>

              <p className="mt-4 max-w-lg sm:text-xl/relaxed font-bold text-white">
                Create precise estimates effortlessly on-the-go with our
                mobile-friendly platform, ensuring accuracy at your fingertips.
              </p>

              <div className="mt-8 flex flex-wrap gap-4 text-start">
                <Link to="/singup">
                  <a className="block w-full rounded bg-secondary px-12 py-3 text-sm font-medium text-white shadow hover:bg-orange-400 focus:outline-none focus:ring active:bg-orange-400 sm:w-auto">
                    Get Started
                  </a>
                </Link>
                <Link to="/talktosales">
                  <a className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-secondary shadow hover:text-orange-400 focus:outline-none focus:ring active:orange-400 sm:w-auto">
                    Learn More
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div className="w-[98%] mx-auto">
          <section className="grid md:justify-center md:items-center grid-cols-12 md:mt-0 mt-[12rem] xs:mt-[6rem] gap-8 lg:gap-0 md:px-4 pt-32">
            <div className=" relative  col-span-12 md:col-span-6 hidden md:block">
              <img className=" top-[-5rem] left-2  absolute" src={lady} />
            </div>

            <div className="col-span-12 md:col-span-6   p-5 lg:ml-20 xl:ml-0">
              <div className="text-[#ff6c02]  text-base mb-[2rem] font-semibold font-['Raleway']">
                ABOUT OUR ESTIMATES
              </div>
              <div className="w-full text-white text-2xl font-bold font-['Raleway'] lg:text-[40.02px] mr-8 leading-[40px] xs:leading-[30px]  lg:leading-[53px]">
                <h4>
                  Precise Project Estimations, Nailed Construction's Commitment
                  to Excellence.
                </h4>
              </div>
              <div className=" text-neutral-400 text-base  font-medium font-['Raleway'] leading-[27px] mt-2">
                At Nailed Construction we understand the importance of providing
                precise evaluations for your construction projects.Our dedicated
                team ensures a streamlined and reliable process, making us your
                trusted partner for all your estimation needs.
              </div>
              <div className="px-[4rem] py-[1rem]  bg-[#ff6c02] mt-8 justify-center items-center gap-2.5 inline-flex">
                <div className="text-white text-xl font-semibold font-['Raleway']">
                  Discover More
                </div>
              </div>
            </div>
          </section>
          {/* ******************************************* */}

          <section className="grid grid-cols-12 gap-5 px-5 md:px-10 mb-8 mt-[9rem] xs:pt-[6rem]">
            <div className="col-span-12 md:col-span-6">
              <div className=" text-[#ff6c02] lg:text-xl font-semibold font-['Raleway'] xs:text-sm">
                GET STARTED
              </div>
              <h3 className="text-white text-3xl lg:text-[45.02px] font-bold font-['Raleway'] leading-[45px] lg:leading-[53px] my-5">
                🏗️ Embarking on Your Project: Share Your Estimates Here
              </h3>
              <div className=" text-neutral-400 text-lg font-medium font-['Raleway'] leading-[27px]">
                <p>
                  {" "}
                  Embark on the exciting journey of your construction project by
                  sharing your estimates with us. Your vision is the foundation,
                  and our commitment is to transform it into a reality.
                  Collaborate with our experienced team, ensuring a seamless and
                  efficient process as we bring your project to life. Share your
                  estimates here, and let's build something extraordinary
                  together!.
                </p>
              </div>
              <div className="wrapper flex flex-col gap-5 mb-16"></div>
            </div>

            <div className="col-span-12 md:col-span-6">
              <img className="w-full" src={quick} />
            </div>
          </section>
          {/* ************************************* */}
        </div>
      </section>
      <Blog />
      <Footer2 />
      {/* <div className="mt-[12rem]">
        
      </div> */}
    </>
  );
}

export default Estimate;
